import React, { FC } from "react"
import { useQuery } from "@apollo/client"
import { Redirect } from "react-router-dom"
import { withoutDomain } from "../../common/url-utils"
import { TPoolRouteProps } from "../../routes.d"
import { POOL_BY_SLUG_QUERY } from "../utils/central-queries"
import LoadingView from "../components/LoadingView"
import NotFound from "./NotFound"
import Status from "../Base/Status"
import { canUseDom } from "@cbs-sports/sports-shared-client/build/cjs/utils/dom-utils"

const PoolSlugFinder: FC<TPoolRouteProps> = ({ match: { params } }) => {
  const { slug } = params
  const { data, loading, error } = useQuery(POOL_BY_SLUG_QUERY, { variables: { slug } })

  if (canUseDom) {
    if (loading) {
      return <LoadingView />
    }
    if (error) {
      return <NotFound />
    }
    if (!data?.pool) {
      // NOTE LL: loook into this to make it work for other games, right now we only use this for brackets
      return (
        <Status code={302}>
          <Redirect to={`/college-basketball/ncaa-tournament/bracket`} />
        </Status>
      )
    }
    return (
      <Status code={302}>
        <Redirect to={withoutDomain(data?.pool?.url)} />
      </Status>
    )
  }
  return <LoadingView />
}

export default PoolSlugFinder
